import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import "react-toastify/dist/ReactToastify.min.css";
import { ProtectedRoute, PublicRoute } from "./components/auth/auth";
import PageNotFound from "./components/misc/pagenotfound";
import LazyLoading from "./components/misc/lazyLoading";

//Platform routes
const CustomForm = lazy(() => import("./pages/platform/custom-form"));
const CustomPage = lazy(() => import("./pages/platform/custom-page"));
const Login = lazy(() => import("./pages/platform/login"));
const Home = lazy(() => import("./pages/platform/home"));
const Users = lazy(() => import("./pages/platform/users"));
const Roles = lazy(() => import("./pages/platform/roles"));

const Voucher = lazy(() => import("./pages/invoice/voucher"));
const InvoiceViewPage = lazy(() => import("./pages/invoice/invoice-view"));
const ReportPage = lazy(() => import("./pages/reports/reports"));
const AddItemMobileView = lazy(() => import("./pages/mobile-view/add-item"));
const CreateCustomer = lazy(() => import("./pages/mobile-view/create-customer"));
const DeliveryPage = lazy(() => import("./pages/mobile-view/delivery-page"));
const CollectionScheduleViewPage = lazy(() => import("./pages/mobile-view/collection-schedule-view-page"));
const CustomerViewPage = lazy(() => import("./pages/mobile-view/customer-view-page"));

function getComponentWithSuspense(
  Comp: LazyExoticComponent<FC<any>>,
  props: any
): JSX.Element {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Comp {...props} />
    </Suspense>
  );
}

function App() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTab = useMediaQuery("(min-width:600px)");
  const isLaptop = useMediaQuery("(min-width:768px)");
  const isDesktop = useMediaQuery("(min-width:992px)");
  const isLarge = useMediaQuery("(min-width:1200px)");
  const props = { isMobile, isTab, isLaptop, isDesktop, isLarge };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="" element={getComponentWithSuspense(Home, props)}>
            <Route
              path="/page/:menu/:menu_profile_id"
              element={getComponentWithSuspense(CustomPage, props)}
            />
            <Route
              path="/form/:menu_profile_id"
              element={getComponentWithSuspense(CustomForm, props)}
            />
            <Route
              path="/users"
              element={getComponentWithSuspense(Users, props)}
            />
            <Route
              path="/roles"
              element={getComponentWithSuspense(Roles, props)}
            />
            <Route
              path="/voucher"
              element={getComponentWithSuspense(Voucher, props)}
            />
            <Route
              path="/invoice_view"
              element={getComponentWithSuspense(InvoiceViewPage, props)}
            />
            <Route
              path="/report-view/:id"
              element={getComponentWithSuspense(ReportPage, props)}
            />
            <Route
              path="/add-item"
              element={getComponentWithSuspense(AddItemMobileView, props)}
            />
             <Route
              path="/create-customer"
              element={getComponentWithSuspense(CreateCustomer, props)}
            />
            <Route
              path="/delivery-page/:id"
              element={getComponentWithSuspense(DeliveryPage, props)}
            />
             <Route
              path="/collection-schedule-page/:id"
              element={getComponentWithSuspense(CollectionScheduleViewPage, props)}
            />
            <Route
              path="/customer-view-page/:id"
              element={getComponentWithSuspense(CustomerViewPage, props)}
            />
          </Route>
        </Route>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="" element={getComponentWithSuspense(Login, props)} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
