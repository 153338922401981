
export class LocalStorageUtils {

    static setStorage(key: string, value: any) {
      localStorage.setItem(key, value);
    }
  
    static getStorage(key: string) {
      return localStorage.getItem(key);
    }
  
    static removeStorage(key: string) {
      localStorage.removeItem(key)
    }
  
}